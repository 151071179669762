import React, { Fragment } from 'react';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, useTheme } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    title: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        marginRight: `${theme.spacing(2)} !important`,
        // textTransform: 'capitalize'
    },
    smallTitle: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        lineHeight: 1.1,
        fontWeight: '700 !important',
        marginRight: `${theme.spacing(2)} !important`,
    },
    backgroundIsOrange: {
        color: theme.palette.common.white
    },
    light: {
        color: theme.palette.primary.light
    },
    caution: {
        color: theme.palette.error.main
    }
}));

export default function SectionHeader(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Fragment>
            <Grid container direction={"row"} flexWrap={"noWrap"} alignItems={"center"}>
                <Grid item>
                    {
                        props.specialHeader
                            ?
                            <Typography
                                variant="h3"
                                sx={{
                                    lineHeight: 1,
                                    fontFamily: 'Cocogoose', fontWeight: 600
                                }}
                                className={clsx({
                                    [classes.backgroundIsOrange]: props.orange,
                                    [classes.smallTitle]: props.small,
                                    [classes.title]: !props.small,
                                    [classes.light]: props.light,
                                    [classes.caution]: props.caution,
                                })}>
                                {props.title}
                            </Typography>
                            :
                            <Typography
                                variant={props.small ? "body1" : "h4"}
                                className={clsx({
                                    [classes.backgroundIsOrange]: props.orange,
                                    [classes.smallTitle]: props.small,
                                    [classes.title]: !props.small,
                                    [classes.light]: props.light,
                                    [classes.caution]: props.caution,
                                })}>
                                {props.title}
                            </Typography>
                    }
                </Grid>
                <Grid item>
                    {
                        props.button && props.extend
                        &&
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            endIcon={<ArrowRightAlt />}
                            onClick={props.clicked}
                        >
                            {props.button}
                        </Button>
                    }
                    {
                        props.button && props.interact
                        &&
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            endIcon={props.icon}
                            onClick={props.clicked}
                        >
                            {props.button}
                        </Button>
                    }
                </Grid>
            </Grid>
            {
                props.underline
                &&
                <Typography
                    variant={props.small ? "body2" : "body1"}
                    color="textSecondary"
                    sx={{
                        textAlign: props.center && 'center'
                    }}
                >
                    {props.underline}
                </Typography>

            }
        </Fragment >
    )
}
