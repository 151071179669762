import React, { Fragment, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Button, Chip, Icon, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { CheckCircleOutline, Delete, DeleteOutline, Edit, HighlightOff, Info, InfoOutlined, PriorityHighOutlined, Refresh, Remove, TaskAltOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import codeStyle from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import CopyButton from './CopyButton';
import State from './State';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    container: {
        maxWidth: '100%'
    },
    code: {
        fontSize: '12px !important',
        padding: `${theme.spacing(0.5)} !important`,
        overflow: 'scroll'
    },
    // row: {
    //     marginBottom: theme.spacing(0.5)
    // },
    // cell: {
    //     borderBottom: 'none !important',
    //     backgroundColor: theme.palette.grey[200]
    // }
}));

export default function ContentTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const now = moment().format('MM/DD/YYYY hh:mm a (UTC)');

    const content = props.data;


    return (
        <TableContainer>
            <Table size="small" className={classes.container}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        content.length > 0 &&
                        content.map((c, i) => (
                            <TableRow hover key={c.label} className={classes.row}>
                                <TableCell sx={{ color: theme.palette.text.secondary }} width={props.auto ? "auto" : 150}>
                                    <Stack direction="row" spacing={.5} alignItems={"center"}>
                                        {c.icon}
                                        <Typography variant="button" color="textSecondary" sx={{ lineHeight: 1 }}>
                                            {c.label}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {
                                        c.custom
                                        && c.custom
                                    }
                                    {
                                        c.value
                                        &&
                                        <Typography sx={{ wordBreak: 'break-word' }} variant="body2">
                                            {c.value}
                                        </Typography>
                                    }
                                    {
                                        c.date
                                        &&
                                        <Typography variant="underline" color="textSecondary">
                                            {c.date}
                                        </Typography>
                                    }
                                    {
                                        c.state
                                        && <State state={c.state} />
                                    }
                                    {
                                        c.link
                                        &&
                                        <Link underline="hover" href={c.link} target="_blank">
                                            <Typography variant="body2">
                                                {c.link}
                                            </Typography>
                                        </Link>
                                    }
                                    {
                                        c.tag && <Chip label={c.tag} size="small" sx={{ margin: 0.2, borderRadius: '5px' }} color="primary" />
                                    }
                                    {
                                        c.tags && c.tags.map((tag, id) => (<Chip size="small" key={tag} label={tag} sx={{ margin: 0.2, borderRadius: '5px' }} color="primary" />))
                                    }
                                    {
                                        c.code
                                        &&
                                        <code>
                                            <pre id={"iframe-snippet"}>
                                                <SyntaxHighlighter className={classes.code} language="html" style={codeStyle}>
                                                    {c.code}
                                                </SyntaxHighlighter>
                                            </pre>
                                        </code>
                                    }
                                    {
                                        c.bool
                                        &&
                                        <Icon>
                                            {
                                                c.bool.is
                                                    ? <TaskAltOutlined color="primary" fontSize="small" />
                                                    : <HighlightOff color="textSecondary" fontSize="small" />
                                            }
                                        </Icon>
                                    }
                                    {
                                        c.chip
                                        &&
                                        <Chip color={c.color} label={c.chip.toUpperCase()} />
                                    }
                                    {
                                        c.nbf
                                        &&
                                        // if not valid yet === warning
                                        <Typography variant="underline" color={'textSecondary'}>
                                            {c.nbf}
                                        </Typography>
                                        // <Typography variant="underline" color={(now > c.nbf) ? 'textSecondary' : 'info.main'}>
                                        //     {c.nbf}
                                        //     <b>{(now > c.nbf) ? "" : ` - (The token is not valid yet)`}</b>
                                        // </Typography>
                                    }
                                    {
                                        c.exp
                                        &&
                                        // if expired === error
                                        <Typography variant="underline" color={'textSecondary'}>
                                            {c.exp}
                                        </Typography>
                                        // <Typography variant="underline" color={(now < c.exp) ? 'textSecondary' : 'error.main'}>
                                        //     {c.exp}
                                        //     <b>{(now < c.exp) ? "" : ` - (The token is expired)`}</b>
                                        // </Typography>
                                    }
                                </TableCell>
                                <TableCell align="right" className={classes.cell}>
                                    {
                                        c.remove &&
                                        <Tooltip title="Remove">
                                            <IconButton
                                                onClick={props.remove(c.label, c.remove)}
                                                size="small"
                                            >
                                                <DeleteOutline fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        c.edit &&
                                        <Tooltip title="Edit">
                                            <IconButton
                                                onClick={props.edit(c.label, c.edit)}
                                                size="small"
                                            >
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        c.info &&
                                        <Tooltip title="Learn More">
                                            <IconButton
                                                onClick={props.info(c.label, c.info)}
                                                size="small"
                                            >
                                                <InfoOutlined fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        c.copy && <CopyButton iconButton={true} copy={c.value || c.date || c.state || c.link || c.tag || c.copyContent} />
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer >
    )
}