import axios from 'axios';
import * as link from '../helper/link-config';
import * as ls from '../helper/ls-vars';

export const BINTU_API = link.BINTU_API;
export const TOKEN_API = link.TOKEN_API;

export const HEADER_X_BINTU = 'X-BINTU-TOKEN';

export const getTokenFromLocalStorage = () => {
    let token = sessionStorage.getItem(ls.BINTU_TOKEN);
    return token;
}

export const validate = ({ email, password }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/user/validate`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                email: email,
                password: password
            }
        };

        axios.request(options)
            .then(function (response) {
                resolve({ success: true, data: response.data });
            }).catch(function (error) {
                let response = {
                    success: false,
                    ...error.response.data.error
                }
                reject(response);
            });
    });
}

export const createOrganisation = ({ email, password, organisation }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/user`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                email: email,
                password: password,
                organisation: organisation
            }
        };

        axios.request(options)
            .then(function (response) {
                resolve({ success: true, data: response.data });
            }).catch(function (error) {
                let response = {
                    success: false,
                    ...error.response.data.error
                }
                reject(response);
            });
    });
}

export const getOrganisation = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/organisation`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const createH5LiveSecureToken = ({ apikey, data }) => {
    let key = apikey || getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${TOKEN_API}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': "application/json",
                [HEADER_X_BINTU]: key
            },
            data
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data
            }
            reject(response);
        });
    });
}

export const resetPassword = ({ email }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/user/password`,
            headers: { 'Content-Type': 'application/json' },
            data: { email }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const setPassword = ({ email, token, password }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/user/password`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                email: email,
                password: password,
                token: token,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const countStates = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/stream/states/count`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getTagCollection = ({ offset, limit, tag }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/tag`,
            params: {
                // 'tags[]': tagList,
                ...(tag && { ["tags[]"]: tag }),
                ...(offset && { offset }),
                ...(limit && { limit }),
            },
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getTranscodingProfiles = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/transcoding_profiles`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const updateTranscodingProfile = ({ streamid, newProfile }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/transcoding_profile`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            },
            data: {
                transcoding_profile: newProfile
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const addTranscodingProfile = ({ streamid, profile }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/transcode`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            },
            data: { profile }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const deleteTranscodingProfile = ({ groupid, streamid }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: `${BINTU_API}/stream/${groupid}/transcode/${streamid}`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const createNewStream = ({ tags, transcodes }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/stream`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                tags: tags,
                ...(transcodes && { transcodes })
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const setOpcodes = ({ streamid, opcodes, immediately }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/opcodes`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                immediately,
                opcodes
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const handleOpcodes = ({ streamid, uniq, type }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/opcodes/${type}/${uniq}`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
        };

        axios.request(options).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const removeOpcodes = ({ streamid, uniq }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: `${BINTU_API}/stream/${streamid}/opcodes`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                uniq
            }
        };

        axios.request(options).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const setOpcode = ({ streamid, opcode, interval }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/opcode`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: { opcode, interval }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getAllowedOperations = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/opcodes`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const setTimecode = ({ streamid, hasTimecode, timecode }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/timecode`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                timecode: hasTimecode,
                ...(timecode && { interval: timecode }),
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const setTags = ({ streamid, tags }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/tag`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                tags
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getStreamById = ({ streamid }) => {
    let apikey = getTokenFromLocalStorage();

    return new Promise((resolve, reject) => {
        const fetchData = (useApiKey) => {
            const headers = {
                'Content-Type': 'application/json',
                ...(useApiKey && apikey && { [HEADER_X_BINTU]: apikey })
            };

            const options = {
                method: 'GET',
                url: `${BINTU_API}/stream/${streamid}/group`,
                headers: headers
            };

            axios.request(options).then(function (response) {
                resolve({ success: true, data: response.data });
            }).catch(function (error) {
                if (useApiKey && error.response && error.response.status === 403) {
                    fetchData(false);
                } else {
                    let response = {
                        success: false,
                        ...error.response.data.error
                    }
                    reject(response);
                }
            });
        }

        fetchData(true);
    });
}

export const redeemInviteToken = ({ token, email, password }) => {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/invitetoken/redeem`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                email,
                password,
                token
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const setWebhook = ({ webhook }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/organisation/webhook`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                webhook: webhook
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const setPublicVOD = ({ isPublic }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/organisation/vod`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                publicVod: isPublic
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const createInviteToken = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            url: `${BINTU_API}/invitetoken`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getUserList = () => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/user`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const deleteUser = ({ userId }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: `${BINTU_API}/user`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            },
            data: { userId: userId }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getStreamCount = ({ filter }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/stream/count`,
            params: filter,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.count });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}


export const getStreamGroupCount = ({ filter }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
            url: `${BINTU_API}/stream/group/count`,
            params: filter,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data.count });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getSingleStreamCollection = ({ filter }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {

        const options = {
            method: 'GET',
            url: `${BINTU_API}/stream`,
            params: { ...filter, order: 'DESC' },
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getStreamCollection = ({ filter }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {

        const options = {
            method: 'GET',
            url: `${BINTU_API}/stream/group`,
            params: { ...filter, order: 'DESC' },
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const deleteStream = ({ streamid }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: `${BINTU_API}/stream/${streamid}`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const setPushUrl = ({ streamid, pushUrl }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/push_url`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey,
            },
            data: {
                url: pushUrl
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const deleteVOD = ({ streamid, streamname }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'DELETE',
            url: `${BINTU_API}/stream/${streamid}/vod`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            },
            data: {
                vod: streamname
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const stopStream = ({ streamid }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/stop`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const unlockStream = ({ streamid }) => {
    let apikey = getTokenFromLocalStorage();
    return new Promise((resolve, reject) => {
        const options = {
            method: 'PUT',
            url: `${BINTU_API}/stream/${streamid}/unlock`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: apikey
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    });
}

export const getPermissions = () => {
    return new Promise((resolve, reject) => {

        const options = {
            method: 'GET',
            url: `${BINTU_API}/permission/list`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: getTokenFromLocalStorage()
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const putPermissions = ({ UserId, permissionProfile }) => {
    return new Promise((resolve, reject) => {

        const options = {
            method: 'PUT',
            url: `${BINTU_API}/user/permission`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: getTokenFromLocalStorage()
            },
            data: { UserId, permissionProfile }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const renewToken = ({ user }) => {
    return new Promise((resolve, reject) => {

        const options = {
            method: 'PUT',
            url: `${BINTU_API}/user/tokenkey`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: getTokenFromLocalStorage()
            },
            data: { user }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}

export const getMetricKey = () => {
    return new Promise((resolve, reject) => {

        const options = {
            method: 'GET',
            url: `${BINTU_API}/metrickey`,
            headers: {
                'Content-Type': 'application/json',
                [HEADER_X_BINTU]: getTokenFromLocalStorage()
            }
        };

        axios.request(options).then(function (response) {
            resolve({ success: true, data: response.data });
        }).catch(function (error) {
            let response = {
                success: false,
                ...error.response.data.error
            }
            reject(response);
        });
    })
}
