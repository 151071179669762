import React, { useEffect, useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import darkCode from "react-syntax-highlighter/dist/esm/styles/prism/material-dark";
import lightCode from "react-syntax-highlighter/dist/esm/styles/prism/material-light";

import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import SectionContainer from '../global/SectionContainer';
import CopyButton from '../global/CopyButton';
import SectionHeader from '../global/SectionHeader';
import { BINTU_ORGA } from '../../utils/helper/ls-vars';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        width: '100%',
        boxShadow: 'none !important',
        background: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]} !important`,
    },
    code: {
        fontSize: '12px !important'
    },
    details: {
        padding: theme.spacing(2)
    },
    descriptionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'

    }
}));

export default function IFrameCodeSnippet(props) {
    const classes = useStyles();
    const theme = useTheme();
    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : false;
    const isSecure = orga ? orga.secure : false;
    const { security, stream, adaptionRule, latencyControlMode } = props;
    const [codeSnippet, setCodeSnippet] = useState("");

    const generateCodeSnippet = (stream, security) => {
        // TO DO: if longer than 1 stream: stream group, otherwise entry :)
        const isStreamGroup = stream.playout.rtmp?.length > 1;

        const entries =
            isStreamGroup ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${security ? security : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}` :
                stream.playout.h5live
                    ?.map((s, index) => {
                        let entry = `entry${index === 0 ? "" : index + 1}`;
                        let info = `${s.info ? `&${entry}.info.bitrate=${s.info.bitrate}` : ""}`
                        let secure = `${isSecure ? `&${entry}.security.jwtoken=${security ? security : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}`
                        return `${entry}.rtmp.streamname=${s.rtmp?.streamname}${secure}${info}`
                    })
                    .join('&');

        const options = [
            ...isStreamGroup ? [`options.adaption.rule=${adaptionRule ? adaptionRule.id : "deviationOfMean2"}`, `startIndex=${adaptionRule ? adaptionRule.index : 0}`] : [],
            `playback.latencyControlMode=${latencyControlMode ? latencyControlMode : "balancedadaptive"}`
        ].join("&");

        const url = `${link.H5LIVE_EMBED_PLAYER}${entries}&${options}`;

        setCodeSnippet(`<iframe 
    frameborder="0" 
    allowfullscreen 
    width="1280" 
    height="720" 
    src="${url}">
</iframe>`)
    }

    useEffect(() => {
        if (Object.values(stream)) {
            generateCodeSnippet(stream, security);
        }
    }, [stream, security])

    return (
        <Accordion className={classes.root} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <SectionHeader
                    title="iFrame Embed Tag (nanoPlayer)"
                    {...props}
                />
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.descriptionWrapper}>
                    <Typography variant="body1" color="textSecondary">
                        Embed the nanoStream H5Live Player using the iFrame tag below.
                    </Typography>
                    <CopyButton copy={codeSnippet} />
                </div>
                <code>
                    <pre id={"code-snippet"}>
                        <SyntaxHighlighter className={classes.code} showLineNumbers language="html" style={theme.palette.mode === 'light' ? lightCode : darkCode}>
                            {codeSnippet}
                        </SyntaxHighlighter>
                    </pre>
                </code>
            </AccordionDetails>
        </Accordion>
    )
}
