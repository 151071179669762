import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/styles';
import { Avatar, Badge, Box, Button, Chip, Grid, Icon, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Tooltip, Typography, alpha } from '@mui/material';
import { AddToQueueOutlined, AdminPanelSettingsOutlined, BlockOutlined, CalendarMonth, Check, CheckCircle, DeleteSweepOutlined, DoneAll, History, Info, KeyboardArrowRight, LiveTvOutlined, OpenInNew, PriorityHigh, StopCircleOutlined, UpcomingOutlined, VideoLibraryOutlined } from '@mui/icons-material';
import { PieChart } from '@mui/x-charts';
import { grey } from '@mui/material/colors';

import { ALERTS, ALERTS_HOW_TO_USE, STREAMS } from '../../utils/helper/link-config';
import { ALERT_COLOR_SCHEME } from '../../analytics/utils/analytics-helper';
import { getIngestAlertsAndAdvices } from '../../analytics/utils/api-requests';
import { countStates } from '../../utils/bintu/api-requests';

import SnackbarMessage from '../global/SnackbarMessage';
import SectionHeader from '../global/SectionHeader';

export default function StreamCountOverview(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { updateAmountOfStreams, getCreated, hideButton } = props;

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState({ all: 0, live: 0, created: 0, ended: 0, deleted: 0, locked: 0, lockedByAdmin: 0 });
    const [alertStats, setAlertStats] = useState(false);
    const [timestamp, setTimestamp] = useState(moment());
    const [allGood, setAllGood] = useState(true);
    const [icons] = useState({
        all: <VideoLibraryOutlined />,
        live: <LiveTvOutlined />,
        created: <AddToQueueOutlined />,
        ended: <StopCircleOutlined />,
        deleted: <DeleteSweepOutlined />,
        locked: <BlockOutlined />,
        lockedByAdmin: <AdminPanelSettingsOutlined />
    });

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setShowError(false);
    }

    const countingStates = () => {
        countStates()
            .then((response) => {
                if (response.success) {
                    setCount(response.data);
                    setLoading(false);
                    if (getCreated) updateAmountOfStreams(response.data.all)
                }
            }).catch((error) => {
                setCount(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const getAlerts = () => {
        getIngestAlertsAndAdvices()
            .then((response) => {
                if (response.success) {
                    let fetchedStats = response.data.statistic;
                    // setTimestamp(response.data.timestamp)
                    setAlertStats(fetchedStats);
                    setAllGood(fetchedStats.alerts === 0);
                }
            }).catch((error) => {
                if (!error.success) {
                    if (error?.errorCode === 105002) return
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const openInNew = (link) => () => { window.open(link, "_blank"); }
    const handleOpenStreams = (state) => () => { navigate(`${STREAMS}${state === "all" ? "" : `?state=${state}`}`) }
    const handleRedirect = (link) => () => { navigate(link) }

    useEffect(() => {
        if (loading) {
            countingStates();
            getAlerts();
        }
    }, [])

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.errorCode}</b> {error.message}
            </SnackbarMessage>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack useFlexGap direction="row" spacing={2} flexWrap="wrap">
                        {
                            // alertStats &&
                            <Box
                                sx={{
                                    p: 2, borderRadius: 2, textAlign: 'center',
                                    bgcolor: theme.palette.info.main, cursor: 'pointer',
                                    backgroundImage: `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(theme.palette.info.main, 0.3)} 15px ), repeating-linear-gradient( ${theme.palette.info.light}, ${theme.palette.info.dark} )`
                                }}
                                onClick={handleRedirect(ALERTS)}
                            >
                                <Icon sx={{ color: alpha(theme.palette.common.white, .5), mb: .5 }}>
                                    <UpcomingOutlined />
                                </Icon>
                                <Typography variant="button" sx={{ display: 'block', lineHeight: 1, color: theme.palette.common.white }}>
                                    Amount of <br />
                                    Alerts
                                </Typography>
                                <Typography variant="h3" sx={{ mt: 0.5, display: 'block', lineHeight: 1, fontWeight: 700, color: theme.palette.common.white }}>
                                    {alertStats ? alertStats?.streamsWithAlerts : <Skeleton />}
                                </Typography>
                            </Box>
                        }
                        {
                            Object
                                .values(count)
                                .map((amount, index) => {
                                    let bgcolor = theme.palette.mode === 'light'
                                        ? `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(theme.palette.common.white, 0.2)} 15px ), repeating-linear-gradient( ${theme.palette.common.white}, ${grey[300]} )`
                                        : `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(theme.palette.grey[900], 0.2)} 15px ), repeating-linear-gradient( ${theme.palette.grey[900]}, ${grey[800]} )`

                                    return (
                                        <Box key={`${amount}-${index}`}
                                            sx={{
                                                p: 2, borderRadius: 2, bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                                                backgroundImage: bgcolor, textAlign: 'center'
                                            }}
                                            onClick={handleOpenStreams(Object.keys(count)[index])}
                                        >
                                            <Icon sx={{ color: alpha(theme.palette.divider, .2), mb: .5 }}>
                                                {icons[Object.keys(count)[index]]}
                                            </Icon>
                                            <Typography color="textSecondary" variant="button" sx={{ display: 'block', lineHeight: 1, }}>
                                                {Object.keys(count)[index]} <br />
                                                Streams
                                            </Typography>
                                            <Typography variant="h3" sx={{ mt: 0.5, display: 'block', lineHeight: 1, fontWeight: 700, color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white }}>
                                                {loading ? <Skeleton /> : amount}
                                            </Typography>
                                        </Box>
                                    )
                                })
                        }
                    </Stack>
                </Grid>
                <Grid
                    item mt={1}
                    xs={12}
                >
                    <Box sx={{ p: .7, bgcolor: 'red', borderRadius: 3, backgroundColor: alpha(alertStats?.alerts > 0 ? theme.palette.error.dark : theme.palette.success.light, 0.2) }}>
                        <Box
                            sx={{
                                boxShadow: theme.shadows[2],
                                backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                                borderRadius: 3, p: 2, border: 2, borderColor: alertStats?.alerts > 0 ? theme.palette.error.light : theme.palette.success.light,
                                backgroundImage: `linear-gradient(to bottom, transparent, ${alpha(alertStats?.alerts > 0 ? theme.palette.error.light : theme.palette.success.light, 0.1)})`,
                            }}
                        >
                            <Stack direction="row" spacing={1} alignItems={"center"} sx={{ fontWeight: 700, color: alertStats?.alerts > 0 ? theme.palette.error.light : theme.palette.success.light }}>
                                {alertStats?.alerts > 0 ? <PriorityHigh fontSize="small" /> : <DoneAll fontSize="small" />}
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Health Status of your {alertStats?.streamsOnline} Live Stream{alertStats?.streamsOnline?.length === 1 ? "" : "s"} ({alertStats?.alerts ?? 0} Alert{alertStats?.alerts?.length === 1 ? "" : "s"})
                                </Typography>
                                <IconButton size="small" onClick={openInNew(ALERTS_HOW_TO_USE)}>
                                    <Info fontSize="inherit" />
                                </IconButton>
                            </Stack>
                            <Chip
                                label={`Time period: ${moment.utc(timestamp).subtract(15, "minutes").format(`MM/DD/YYYY hh:mm a`)} - ${moment.utc(timestamp).format(`hh:mm a`)} (UTC)`}
                                size="small" icon={<History />}
                                sx={{ borderRadius: 1, textTransform: 'uppercase' }}
                            />
                            {
                                alertStats?.streamsOnline?.length > 0 &&
                                <Stack useFlexGap spacing={1} direction="row" sx={{ mt: 1 }}>
                                    {
                                        Object.keys(ALERT_COLOR_SCHEME).map((alert, index, keyWords) => {
                                            return (
                                                <Box
                                                    key={alert}
                                                    sx={{
                                                        p: 1, borderRadius: 2, textAlign: 'right',
                                                        backgroundImage: `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(ALERT_COLOR_SCHEME[alert].color, 0.2)} 15px ), repeating-linear-gradient( ${ALERT_COLOR_SCHEME[alert].color}, ${alpha(ALERT_COLOR_SCHEME[alert].color, 0.5)} )`
                                                        // backgroundImage: `linear-gradient(to bottom, transparent, ${alpha(ALERT_COLOR_SCHEME[alert].color, 0.4)}, ${alpha(ALERT_COLOR_SCHEME[alert].color, 0.6)})`,
                                                    }}
                                                >
                                                    <Typography variant="h3" sx={{ fontWeight: 700, color: theme.palette.common.white }}>
                                                        {alertStats[alert]}
                                                    </Typography>
                                                    <Typography variant="button" sx={{ color: theme.palette.common.white }}>
                                                        {ALERT_COLOR_SCHEME[alert].title}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        )
                                    }
                                </Stack>
                            }
                            {
                                !hideButton &&
                                <Stack direction="row">
                                    <Button
                                        size="small" variant="outlined"
                                        color={alertStats?.alerts > 0 ? "error" : "success"}
                                        endIcon={<KeyboardArrowRight />}
                                        sx={{ mt: 1 }} onClick={handleRedirect(ALERTS)}

                                    >
                                        See overview
                                    </Button>
                                </Stack>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>

    )
}
