import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { countriesWithCoordinates } from '../../utils/helper/country-coords';

window.mapboxgl.accessToken = 'pk.eyJ1IjoiYW5uaW5pbmFubyIsImEiOiJjbHdhaWczaWwwZTNtMmxzMmd2aDRmMnliIn0.jA58bmXspRtWGIpR88DN0A';

export default function Map(props) {
    const { data } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [zoom, setZoom] = useState(1);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLng(longitude);
            setLat(latitude);
        });

        if (map.current) return;

        map.current = new window.mapboxgl.Map({
            container: mapContainer.current,
            projection: 'mercator',
            style: 'mapbox://styles/mapbox/light-v11',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false
        });

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(3));
        });

        map.current.on('load', () => {
            map.current.resize();
        });

    }, []);

    useEffect(() => {
        if (data && map.current !== null) {
            data.forEach((d) => {
                const country = countriesWithCoordinates.find((c) => c.alpha2 === d.alpha2);
                console.log(country)
                if (country) {
                    new window.mapboxgl.Marker({ color: 'black', rotation: 45 })
                        .setLngLat([country.longitude, country.latitude])
                        .addTo(map.current);
                }
            })
        }
    }, [data, map.current])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box
                    component="div"
                    height={'60vh'}
                    ref={mapContainer}
                    className="map-container"
                />
            </Grid>
        </Grid>
    )
}
